//src/pages/_app.tsx
import type { AppProps } from 'next/app';
import { useState, useEffect } from 'react';
import Navbar from '@Navbar';
import Footer from '@Footer';
import Header from '@Header';
import { fetchNavbarProps } from '@functions';
import { NavbarProps } from '@interfaces';

import '../styles/globals.css';

function MyApp({ Component, pageProps }: AppProps) {
  const [navbarProps, setNavbarProps] = useState<NavbarProps>({ initialNavItems: [] });

  useEffect(() => {
    const loadNavbarProps = async () => {
      try {
        const props = await fetchNavbarProps();
        setNavbarProps({ initialNavItems: props });
      } catch (error) {
        console.error('Failed to fetch navbar props:', error);
      }
    };
    loadNavbarProps();
  }, []);

  return (
    <>
      <Header />
      <Navbar {...navbarProps} />
      <Component {...pageProps} />
      <Footer />
    </>
  );
}

export default MyApp;